import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
import { Link } from "gatsby";
import { ABOUT_URL } from "../constants/pageUrls";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Today at the White House in Washington D.C., MasterCard announced its partnership with PayPerks for public sector prepaid debit cards in the US. The announcement comes as a part of the White House Summit on Financial Capability and Empowerment.`}</p>
    <p>{`The Office of the Press Secretary at the White House issued this `}<a parentName="p" {...{
        "href": "http://www.whitehouse.gov/the-press-office/2012/05/10/commitments-promote-financial-empowerment-across-country"
      }}>{`press release`}</a>{`. Here`}{`’`}{`s the paragraph on PayPerks:`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "758px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACM0lEQVQoz33RbVOiUBQHcD7/1rdo31Xauk6ECvL8cK9wuyCIUjkgYmCpsKnrmrADbY1NM/t7dd/8zzn3HKI4kr8rimJbyfP8cDi8frGvEMeBj/yf3Q5jvFwui6LY7/dvga9ViI/XcYmiKNbr9Wq1OprqX5X9uzL80bAKvGw2Zeb5+TlJEt/3w0k4n8+Xy+VisSg+/7AMh2E4Go4e7u/H47Esy81mU1Fky7LuPC/wA3cwMLFJXl//bDTcgeuNRsOh6w7cxWKR5zmhyLKqqrqu93o9Xdc1VaU7Hdd1exDi21tN0wzDaFEUhFBVFKgBVVE0TTMxPry+EpIosiwr8Lwiy4IgcCx7Q5K1y8sWRdEdGmjg4vz8hiQ5lu0yDNvtSqJEUdSd55XbvkUIQgg0DQIIAVBLyuX5BdvtChwPVNBlGJ7jZEmSRFESRQgAwzCTINj93hEAgKtavV6r1Wv1TruNEKI7dOPqB8eyFEX1+31BEFpUS+B58prstNsMTVM3lOM4ZWfDML6fnZ2enp58O2k0Gr7vI4Qcx1EVVZKk0XCEEOI5HgDAc5yqlIMJgmBi7DgOofd6QRBsN9unp6c4jh3bNjG2TNO27XKvzsCyLFVRMMa2bWOMEUJGRZZkwvM8wzDCySSO4yxNszR9u2ocx9F0Gk2nsyh6nM2iSliJomgahkkcE1mWzedzdzgcOM744SFJkpfKer3e/Nd2uyWyNPU8r9/vz2azSdU/TdPVZ2maZp/9qvwF6VDb8mSW2v4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/29623d7467bfbc160448de1e7f02bc66/7f61c/payperks_whitehouse.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/29623d7467bfbc160448de1e7f02bc66/c4e8e/payperks_whitehouse.webp 758w"],
            "sizes": "(max-width: 758px) 100vw, 758px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/29623d7467bfbc160448de1e7f02bc66/e17e5/payperks_whitehouse.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/29623d7467bfbc160448de1e7f02bc66/c8e86/payperks_whitehouse.png 758w"],
            "sizes": "(max-width: 758px) 100vw, 758px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/29623d7467bfbc160448de1e7f02bc66/c8e86/payperks_whitehouse.png",
            "alt": "payperks whitehouse",
            "title": "payperks whitehouse",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`The ‘PayPerks for Public Sector’ program, which is an optional feature of MasterCard’s federal, state and local government prepaid card programs that fuses online education with sweepstakes-based incentives in order to provide consumers with information on the most effective use of their government-sponsored prepaid cards.`}</p>
    </blockquote>
    <p>{`To learn more about PayPerks for Public Sector please feel free to `}<Link to={ABOUT_URL} mdxType="Link">{`contact us`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      